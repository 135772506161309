import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Service from "./pages/Service";
import './App.css';
import Header from "./common/header"
import Footer from "./common/footer"
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";

function App() {
    return (
        <>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/service" element={<Service />} />
                <Route path="/contact" element={<Contact />} />
               <Route path="/privacy-poclicy" element={<Privacy/>}/>
            </Routes>
            <Footer/>
        </>
    )
}


export default App;

