import React, { useState } from "react";
import { angular, custome_software, design_development, ecommerce, home_banner, it_service, money, node, php, react, software_development, support, thums_up, ux_design, web_hosting, web_solution } from '../assets/img/img';
import { Link } from 'react-router-dom';

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const testimonials = [
    {
      text: "Real nice design and fantastic subject matter, practically nothing else we require.",
      author: "Nikhil",
    },
    {
      text: "This is the very first time I frequented your web page and thus far? I amazed with the research you made to create this particular publish incredible. Wonderful job!",
      author: "Himanshu Chauhan",
    },
    {
      text: "Since the member of this website is working, no question very quickly it will be well-known, due to its quality contents.",
      author: "Deepak Sharma",
    },
  ];

  const totalSlides = testimonials.length;

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + totalSlides) % totalSlides);
  };

  const selectSlide = (index) => {
    setCurrentSlide(index);
  };
  return (
    <div>
      {/* Hero Section */}
      <section>
        <div className="main-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="hero-content">
                  <h1>Tailored IT solutions for your business needs.</h1>
                  <p>Turn your innovative business idea into reality with custom software solutions.</p>
                  <Link className="btn btn-primary" to="#">
                    Get Started
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 home-img">
                <img className="img-fluid" src={home_banner} alt="Home Banner" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Boxes Area */}
      <section className="bg-f7fafd">
        <div className="boxes-area">
          <div className="container">
            <div className="row justify-content-center">
              {[
                {
                  img: support,
                  title: "Great Support",
                  text: "Empowering your journey with exceptional service and unwavering support."
                },
                {
                  img: money,
                  title: "Affordable Price",
                  text: "Quality you can trust at a price you can afford—your satisfaction guaranteed."
                },
                {
                  img: thums_up,
                  title: "Availability",
                  text: "Always here when you need us, ensuring reliable service around the clock."
                },
                {
                  img: it_service,
                  title: "IT Consulting",
                  text: "Strategic IT solutions tailored to drive your success and innovation."
                }
              ].map((box, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div className="single-box">
                    <div className="icon">
                      <img src={box.img} alt={box.title} />
                    </div>
                    <h3>
                      <Link to="#">{box.title}</Link>
                    </h3>
                    <p>{box.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Services Area */}
      <section>
        <div className="services-area p-50">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img
                  src={design_development}
                  className="img-fluid"
                  alt="Design and Development"
                />
              </div>
              <div className="col-md-6 services-content">
                <h2>Design &amp; Development</h2>
                <p>
                  We have a dedicated team of web developers and designers. And they are passionate
                  about their work. We help most startup companies and those who want to start their
                  business online through web and application.
                  <br />
                  <br />
                  And the following things that we work on in WordPress Development, Web Designing,
                  Landing Pages, E-Commerce Websites, Content Writing and so on.
                </p>
                <div className="row">
                  {[
                    { img: react, name: "React Development" },
                    { img: angular, name: "Angular Development" },
                    { img: node, name: "Node JS" },
                    { img: php, name: "PHP" }
                  ].map((service, index) => (
                    <div className="col-lg-6 col-md-6" key={index}>
                      <div className="box">
                        <img src={service.img} className="img-fluid" alt={service.name} />
                        <span className="ps-4">{service.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Area */}
      <section>
        <div className="features-area pt-80 p-50 bg-f7fafd">
          <div className="container">
            <div className="section-title">
              <h2>Our Features</h2>
              <div className="bar"></div>
            </div>
            <div className="row">
              {[
                {
                  img: ux_design,
                  title: "UI/UX Design",
                  text: "Crafting digital experiences that delight users while solving real problems."
                },
                {
                  img: software_development,
                  title: "Software Development",
                  text: "Turning ideas into functional, reliable, and scalable software solutions."
                },
                {
                  img: web_solution,
                  title: "Web Solution",
                  text: "Empowering businesses with custom web solutions that drive growth and enhance online presence."
                },
                {
                  img: custome_software,
                  title: "Custom Software",
                  text: "Building custom software that perfectly aligns with your unique business processes and goals."
                },
                {
                  img: ecommerce,
                  title: "E-Commerce",
                  text: "Revolutionizing retail by delivering seamless, secure, and scalable e-commerce experiences."
                },
                {
                  img: web_hosting,
                  title: "Website Hosting",
                  text: "Delivering dependable website hosting that ensures your site stays live, fast, and secure."
                }
              ].map((feature, index) => (
                <div className="col-lg-6 col-md-6" key={index}>
                  <div className="single-features">
                    <div className="icon">
                      <img src={feature.img} alt={feature.title} />
                    </div>
                    <h3>
                      <Link to="#">{feature.title}</Link>
                    </h3>
                    <p>{feature.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="py-5">
      <div className="container">
        <div className="section-title">
          <h2>Our Testimonial</h2>
          <div className="bar"></div>
        </div>
      </div>
      <div className="container slideshow-container">
        <div className="mySlides">
          <q>{testimonials[currentSlide].text}</q>
          <p className="author">~ {testimonials[currentSlide].author}</p>
        </div>
        <button className="prev" onClick={prevSlide}>
          ❮
        </button>
        <button className="next" onClick={nextSlide}>
          ❯
        </button>
      </div>
      <div className="dot-container">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => selectSlide(index)}
          ></span>
        ))}
      </div>
    </section>
    </div>
  )
}

export default Home