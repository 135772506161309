import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../assets/img/img";

const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="container">
    <nav className="navbar navbar-expand-md navbar-light">
      <Link className="navbar-brand" to="/">
        <img
          alt="logo"
          loading="lazy"
          width="200"
          src={logo}
        />
      </Link>
      <button
        className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`}
        type="button"
        onClick={toggleCollapse}
        aria-controls="navbarSupportedContent"
        aria-expanded={!isCollapsed}
        aria-label="Toggle navigation"
      >
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </button>
      <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/service">Service</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
    </div>
  );
}

export default Header;
